// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	#site-header{
		.navbar{
			.navbar-nav{
				a.nav-link{
					font-size: 13px;
					padding: 0.4rem;
				}
			}
			.btn{
				font-size: 12px;
			}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 1050px) {  
	#site-header{
		.navbar{
			.navbar-nav{
				a.nav-link{
					font-size: 12px;
					padding: 0.3rem;
				}
			}
		}
	}

	.navbar-brand{
		img{
			max-width: 205px;
		}
	}
}




// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	#intro{
		.logo{
			width: 80px;
		}
		.slogan{
			font-size: 35px;
		}
	}

	section.testimonials{
		.item-details{
			.content{
				font-size: 14px;
				max-width: 650px;
			}
		}
	}
}


// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	section.training{
		.section-content{
			padding: 2rem;
		}
	}
	section.training{
		h2{
			font-size: 24px;
		}
	}

	section.testimonials{
		.item-details{
			.content{
				max-width: 400px;
			}
		}
	}

	#our-team{
		.no-gutters{
			margin-left: -15px;
			margin-right: -15px;
		}
		.member{
			margin: 5px;
			.details{
				opacity: 1;
				padding: 10px;
				display: block;
				position: relative;
				left: initial;
				top: initial;
				bottom: initial;
				right: initial;
				*{
					opacity: 1;
					transform: translateY(0px) !important;
				}
				h4{
					margin: 0;
					padding-bottom: 10px;
					font-size: 14px;
				}
				ul{
					li{
						font-size: 12px;
					    line-height: 17px;
					}
				}
			}
		}
	}
}



// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
	#intro{
		.logo{
			width: 65px;
			margin-top: 50px;
		}
		.slogan{
			font-size: 28px;
			padding: 0 10px;
		}
	}
	section.training{
		.section-content{
			padding: 2rem 1rem;
		}
	}
	section.training{
		h2{
			font-size: 20px;
		}
	}

	section.testimonials{
		.item-details{
			.content{
				max-width: 300px;
				font-size: 12px;
			}
		}
	}
}





@import "variables";
@import "node_modules/bootstrap/scss/bootstrap";


h1,h2,h3,h4,h5,h6{font-family:'Lato', sans-serif; text-transform: uppercase;}
.btn,input,textarea{font-family:'Lato', sans-serif;}

#site-header{
	font-family: 'Lato', sans-serif;

	.navbar{
		background-color: rgba(255,255,255,0.9);
		text-transform: uppercase;
		font-size:14px;
	}
	.nav-link.active{
		border-bottom:1px solid #333;
	}

	.navbar-brand{margin-right: 2rem;}
	.nav-link{padding: 0.7rem;}
}
.section-title{
	
	font-size:16px;
	letter-spacing: 2.5px;
	text-align: center;
	margin-bottom: 2.5rem;

}
.sborderBottom:after{
	content: ' ';
	border-bottom: 1px solid $light-blue;
	width: 3rem;
	display: block;
	margin: 10px auto;
}


.page-content section{
	padding: 4rem 0 3rem 0;

	&.darkbg{
		background-color: $gray-200;
		.section-title{color:#000}
	}
}


#intro{
	
	font-family: 'Arial', sans-serif;
	color:#000;
	
	.logo{margin-bottom:1.5rem}

	.slogan{line-height: 1.6;font-size:45px; text-transform: uppercase; color:#fbfbfb; text-shadow:0px 0px 5px rgba(0, 0, 0, 0.6)}
	
	h4{text-transform: none;}
}

#our-team{
	.member{
		position: relative;
		overflow: hidden;
		padding: 0 25px;
	
		.avatar{
			transition: all 0.4s ease-in-out;
		}
		.details{
			text-align: center;
			padding: 15px;
			color:#000;
			opacity: 0;
			background-color: #fff;
			border-top-right-radius:8px;
			border-top-left-radius:8px;

			transition: all 0.4s ease-in-out;
			position: absolute; left: 0; right: 0;top: 0; bottom: 0; 
			
			*{opacity: 0}

			h4{
				transform: translateY(-100px);
				transition: all 0.2s ease-in-out;
				margin: 2rem 0;
				padding-bottom: 1rem;
				border-bottom:1px solid #666;
			}

			p,ul{
				transform: translateY(100px);
				transition: all 0.2s linear;
			}
		}

		&:hover{
			.avatar img{transform: scale(1.1);}
			.details{opacity: 0.8}

			.details *{opacity: 1; transform: translateY(0px);}

		}
	}
	
}
section.who-we-are{
	text-align: center;
	p{max-width: 80%; margin: 0px auto;}
}


section.training{
	font-size:14px;

	.section-content{
		padding: 3rem;
	}
	h2{
		font-size:28px;
		color:#000;
		margin-bottom: 1.5rem;
	}

	ul{font-family: 'Lato', sans-serif; }

	.sborderBottom:after{
		margin:10px 0;
		width: 100px;
	}

}

section.testimonials{
	background: url(../img/cosmomia_nedediler-e1503607766203.jpg) top center no-repeat;
	background-size: cover;
	position: relative;
	overflow: hidden;
	padding-top: 3rem;
	font-size:16px;;

	.carousel{padding-bottom: 3rem;}
	.carousel-indicators{bottom: 0}
	.carousel-indicators li{width: 8px;height: 8px;}

	.item-details{
		color: #fff;
		text-align: center;
		.content{font-style: italic; max-width: 700px; margin:0px auto;line-height: 2;}
	}

	h6{margin-top: 20px;}

	.dark-overlay{background-color: rgba(0,0,0,0.5); position: absolute; top:0; right: 0; left: 0; bottom: 0;}
}

#latest-blog{
	h5 a{color:#000; text-decoration: none;}
	.card-text{font-family:'Lato', sans-serif;}
}

section.how-we-do{
	.service{
		background-color: #fff;
		h4{font-size:16px; color:$primary}
		svg{font-size:60px; margin:1rem 0; }
		padding:2rem;
		text-align: center;
	}
}

#site-footer{
	padding: 0 0 2rem 0;
	background-color: #121212;
	color:#9f9f9f;
	font-size:0.8rem;
	
	a{text-decoration: none; }

	.icon-detail{
		font-family:'Lato', sans-serif;
		svg{color:#fff; font-size:50px; display: block; margin:1.5rem auto}
	}
	.links{
		a svg{color:#535353; font-size:20px; margin:10px 5px;}
		a:hover svg{color:$primary}
		
	}

	a,.section-title{color:#fff}
	a{display: inline-block;}
}

#footer-map{
	margin-bottom: 2rem;
}

#contact-form{
	padding: 2rem 0;

	.alert{
		p,ul{margin-bottom: 0;}
	}
	.form-control{
		background-color: #121212;
		border:1px solid #2d2d2d;
		color: #9f9f9f;
	}
	.form-control:focus{
		border-color:$primary;
		box-shadow: none;
	}

}

.page-content#blog{
	.post-intro{
		.dark-overlay{background-color: rgba(0,0,0,0.5); position: absolute; top:0; right: 0; left: 0; bottom: 0;}

		h1{ 
			font-size: 40px; text-transform: uppercase;
		}
	
		.container{z-index: 50; position: relative;}

		text-align: center;
		padding: 6rem 0;
		color: #fff;
		margin-bottom: 2rem;
		overflow:hidden;
		position: relative;
	}
}


.card-title{
	line-height: 1.4;
}

@import "responsive.scss";
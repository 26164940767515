// Body
$font-family-sans-serif: 'Merriweather', serif;

$font-size-base:0.825rem;
$line-height-base: 2;

$body-color: #7e7d7d;

$light-blue: #5ed9e7;
$primary: $light-blue;

$navbar-light-color: #000;